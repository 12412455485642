<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd='DEVICE_CD'
            type="none"
            itemText="codeName"
            itemValue="code"
            name="deviceType"
            label="시스템 구분"
            v-model="searchParam.deviceType"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="0M"
            label="LBLPERIOD"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <apexchart 
          class="accidentChart"
          ref="kindOcuurChart2" 
          height="550" 
          :options="kindOcuurChart2.chartOptions" 
          :series="kindOcuurChart2.series"></apexchart>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          title="메뉴별 접속건수"
          tableId="menuLog"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'menu-log',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        period: [],
        userId: '',
        plantCd: null,
        deviceType: 'P',
      },
      grid: {
        columns: [
          {
            name: 'menuPath',
            field: 'menuPath',
            label: '메뉴경로',
            align: 'left',
            sortable: true,
          },
          {
            name: 'cnt',
            field: 'cnt',
            label: '접속 건수',
            type: 'cost',
            sortable: true,
            style: 'width:120px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
      kindOcuurChart2: {
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
          },
          title: {
            text: '메뉴별 접속건수 TOP 20'
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
        series: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sys.log.menulog.list.url + '/status'
      this.searchParam.period = [this.$comm.getPrevMonth(1) + '-01', this.$comm.getToday()];
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.getChart();
      },);
    },
    getChart() {
      this.kindOcuurChart2.series = [];
      this.kindOcuurChart2.chartOptions.labels = [];
      let _data = [];
      for(var i=0; i < this.grid.data.length; i++) {
        if (i < 20) {
          this.kindOcuurChart2.chartOptions.labels.push( (i+1) + ': ' + this.grid.data[i].menuNm)
          _data.push(Number(this.grid.data[i].cnt))
        }
      }
      this.kindOcuurChart2.series = _data
      this.$nextTick(function () {
        this.$refs['kindOcuurChart2'].refresh();
        this.kindOcuurChart2.chartWidth = '90%';
      })
    }
  }
};
</script>

<style lang="sass">
.accidentChart
  padding: 20px !important
  border: 1px solid #dee2e6
  border-top: 3px solid #dee2e6
</style>